import * as React from 'react';
import styled from 'styled-components';
import servicesBackground from "../../images/services-background.jpg";
import { ReactNode } from 'react';
import wavesThird from "../../images/waves-third.jpg";
import servicesHeader from "../../images/services-header.svg";


type HeaderServicesProps = {
    title: string | ReactNode; 
    description: string | ReactNode;
    headerImage?: string | ReactNode;
}


const Background = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100vh;
    background-image: url(${servicesBackground});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
    align-items: center;
    padding-bottom: 0;

    @media (min-width: 1200px) and (max-width: 1600px) {
        background-size: cover;
        height: 135vh;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
        height: 800px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        padding-top: 75px;
        flex-direction: column;
        align-items: center;
        background-size: cover;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        display: block;
        height: auto;
        background-position: right top;
        background-size: 300%;
        padding-top: 75px;
        padding-bottom: 40px;
        background-image: url(${wavesThird});
    }
`;

const HeaderBox = styled.div`
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

        @media (min-width: 992px) and (max-width: 1199.98px) { 
            flex-basis: 100%;
            justify-content: center; 
        }

        @media (min-width: 768px) and (max-width: 991.98px) {
            flex-basis: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: ${({ theme }) => theme.paddingTablet};
    }
`;

const ImageBox = styled.div`
    flex-basis: 50%;
    padding: ${({ theme }) => theme.paddingDesktop}; 
    text-align: right;

    @media (min-width: 1200px) and (max-width: 1600px) {
        padding: ${({ theme }) => theme.paddingLaptop}; 
    }

    @media (min-width: 992px) and (max-width: 1199.98px) { 
        padding: ${({ theme }) => theme.paddingLaptop}; 
        flex-basis: 0%;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: ${({ theme }) => theme.paddingTablet}; 
        flex-basis: 0%;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        padding: ${({ theme }) => theme.paddingPhone}; 
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        height: auto;
     }

`;


const StyledToolsChildren = styled.div`
    justify-content: flex-end;
    display: flex;
`;

const StyledH1 = styled.h1`
    color: ${({ theme }) => theme.primaryColor};
    margin: 0 0 32px 0;

    @media (max-width: 767.98px) {
        width: 100%;
        text-align: left;
        padding: ${({ theme }) => theme.paddingPhone}; 
     }
`;

const StyledP = styled.p`
    text-align: justify;
    width: 53%;
    margin: 0 0 89px 0;
    font-size: 24px;
    
    @media (min-width: 768px) and (max-width: 991.98px) {
        width: 100%;
    }


    @media (min-width: 1px) and (max-width: 767.98px) {
        width: 100%;
        padding: ${({ theme }) => theme.paddingPhone}; 
        margin: 0;
    }
`;

const ServicesImage = styled.img`
    display: none;

    @media (min-width: 1px) and (max-width: 767.98px) {
        display: block;
        width: 326px;
    }
`;


export const HeaderServices: React.FC<HeaderServicesProps> = ({
    title, 
    description, 
    headerImage, 
 }) => {

 
    return (
        <Background>
            <HeaderBox>
                <StyledH1>{title}</StyledH1>
                <StyledP>{description}</StyledP>
            </HeaderBox>
            <ImageBox>
                <StyledToolsChildren>
                    {headerImage}
                </StyledToolsChildren>
                <ServicesImage src={servicesHeader} alt="logo services" loading="eager"/>
            </ImageBox>
        </Background>
    );
}

        
        
        
        
   
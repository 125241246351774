import * as React from 'react';
import Layout from '../components/layout';
import ColumnWrapperWithBg from '../components/organisms/ColumnWrapperWithBg';
import DataboutTools from '../components/organisms/DataboutTools';
import NeedMore from '../components/organisms/NeedMore';
import ColumnItemWithBg, { ColumnItemWithBgType } from '../components/molecules/ColumnItemWithBg';
import PageContent from '../components/organisms/PageContent';
import { HeaderServices } from '../components/organisms/HeaderServices';
import styled from 'styled-components';
import SEO from '../components/seo';
import transportImage from '../images/transport.svg';
import powerImage from '../images/power-engineering.svg'
import environmentImage from '../images/environment.svg';
import estateImage from '../images/estate.svg';
import uavImage from '../images/uav.svg';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';


const ToolsDesktop = styled.div`
	display: flex;

	@media (max-width: 767.98px) {
		display: none;
        height: 0;
    }
    
    @media (min-width: 768px) and (max-width: 991.98px) {
        display: none;
    }
    
    @media (min-width: 992px) and (max-width: 1249.98px) { 
        display: none;
    }
`;

const ToolsMobile = styled.div`
	display: none;

	@media (max-width: 767.98px) {
        display: block;
    }
    
    @media (min-width: 768px) and (max-width: 991.98px) {
		display: block;
    }
    
	@media (min-width: 992px) and (max-width: 1249.98px) { 
        display: block;
    }
    `;


const Services: React.FC = () => {
    const {t} = useTranslation();

    const ServicesChildren: ColumnItemWithBgType[] = [
        {
            name: t('services.transport'),
            image: transportImage,
            link: "/uslugi/transport"
        },
        {
            name: t('services.powerEngineering'),
            image: powerImage,
            link: "/uslugi/energetyka"
        },
        {
            name: t('services.environment'),
            image: environmentImage,
            link: "/uslugi/srodowisko"
        }
        //,
        //{
        //    name: t('services.realEstate'),
        //    image: estateImage,
        //    link: "/uslugi/nieruchomosci"
        //},
        //{
        //    name: t('services.drones'),
        //    image: uavImage,
        //    link: "/uslugi/drony"
        //}
    ]
    
    return ( 
        <Layout>
            <SEO title={t('services.title')} description={t('services.description')}/>
            <HeaderServices
                title={t('services.title')}
                description={t('services.description')}
                headerImage={
                    <ToolsDesktop>
                        {ServicesChildren.map((item, index) => <ColumnItemWithBg key={index} image={item.image} name={item.name} link={item.link} size="short"/>)}
                    </ToolsDesktop>
                }
            />
            <PageContent>
                <ToolsMobile>
                    <ColumnWrapperWithBg itemsArr={ServicesChildren} size="short" title={t('services.title')}/>
                </ToolsMobile>
                <DataboutTools title="Databout.Tools" subTitle={t('toolsAd.subTitle')} description={t('toolsAd.description')} link="/tools"/>
                <NeedMore/>
            </PageContent>
        </Layout>
     );
}


export const pageQuery = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}`
 
export default Services;
